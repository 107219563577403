import { useEffect, useRef, useState } from "react";

interface Props {
  close: (value: boolean) => void;
  handleConfirm: (chargerId: string, pin: number) => void;
}

const AddCharger = ({ close, handleConfirm }: Props) => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [chargerId, setChargerId] = useState("");
  const [currentStateOfCharge, setCurrentStateOfCharge] = useState("");

  const [translateY, setTranslateY] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const [activationCode, setActivationCode] = useState("");
  const [activationCodes, setActivationCodes] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  useEffect(() => {
    const activationCode = activationCodes.join("");
    setActivationCode(activationCode);
  }, [activationCodes]);

  const handleDigitChange = (index: number, value: string) => {
    if (value.length > 1) {
      value = value.charAt(value.length - 1);
    }

    const newCodes = [...activationCodes];
    newCodes[index] = value;
    setActivationCodes(newCodes);

    const nextIndex = index === 3 ? 3 : index + 1;
    const nextInputRef = inputRefs[nextIndex].current;
    if (nextInputRef && value) {
      nextInputRef.focus();
    }
  };

  const isFormValid =
    chargerId.trim() !== "" &&
    inputRefs.every((inputRef) => inputRef.current?.value.trim() !== "");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowDateModal(false);
      }
    };

    if (showDateModal) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDateModal]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      close(false);
    }, 150);
  };

  return (
    <>
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-0"
      ></div>

      <div
        className="flex flex-col rounded-t-[10px] items-center fixed pt-10 pb-8 px-8 z-1000 bg-white bottom-0 left-0 right-0"
        style={{
          transition: isClosing
            ? "transform 0.3s ease-in"
            : translateY === 0
              ? "transform 0.3s ease"
              : "none",
          transform: isClosing
            ? "translateY(100%)"
            : `translateY(${translateY}px)`,
          overscrollBehavior: "none",
        }}
      >
        <img
          onClick={handleClose}
          className="absolute top-4 right-6"
          width={16}
          height={16}
          src="/assets/svg/exit/cross.svg"
          alt=""
        />
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col items-center w-full gap-3">
            <div className="flex flex-col gap-2.5 w-full">
              <label className="text-[13px] font-semibold text-dark-green">
                Enter Charger ID
              </label>
              <input
                value={chargerId}
                onChange={(e) => setChargerId(e.target.value)}
                className="text-[14px] focus:border-light-green font-semibold text-[#000] rounded-[5px] px-2 py-2 border-2 border-[#F7F7F7] focus:outline-none w-full"
                type="text"
              />
            </div>
            <div className="flex flex-col mb-6 gap-2.5 w-full">
              <label className="text-[13px] font-semibold text-dark-green">
                Enter PIN
              </label>
              <div className="flex gap-4">
                {inputRefs.map((ref, index) => (
                  <input
                    ref={ref}
                    key={index}
                    value={activationCodes[index]}
                    onChange={(e) => handleDigitChange(index, e.target.value)}
                    className="text-center text-[14px] font-semibold text-[#000] rounded-[5px] px-2 py-2 border-2 border-[#F7F7F7] focus:outline-none focus:border-light-green w-full"
                    type="number"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => handleConfirm(chargerId, Number(activationCode))}
          className={`text-[14px] w-full font-bold py-2 px-5 rounded-[5px] transition duration-200 ${isFormValid
            ? "bg-light-green text-white"
            : "bg-[#D6D6D6] text-[#515151]"
            }`}
          disabled={!isFormValid}
        >
          Confirm
        </button>
      </div>
    </>
  );
};

export default AddCharger;
