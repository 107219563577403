import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";


const StatusBar = observer(() => {
  const { chargerStore } = useStore();

  useEffect(() => {
    chargerStore.getChargersStatusCount();
  }, [chargerStore])

  return (
    <div className="flex justify-between w-full py-2.5 px-2 rounded-[5px] border-2 border-[#E6E6E6] mb-6">
      <div className="flex gap-1 text-[14px] font-semibold text-[#F28A05] items-center bg-[#F7F7F7] rounded-[5px] px-2 py-1">
        <p>{chargerStore.chargersStatusCount?.connected}</p> <p className="text-[11px] font-semibold text-dark-green">Connected</p>
      </div>
      <div className="flex gap-1 text-[14px] font-semibold text-[#DA3333] items-center bg-[#F7F7F7] rounded-[5px] px-2 py-1">
        {chargerStore.chargersStatusCount?.disconnected}
        <p className="text-[11px] font-semibold text-dark-green">
          Disconnected
        </p>
      </div>
      <div className="flex gap-1 text-[14px] font-semibold text-light-green items-center bg-[#F7F7F7] rounded-[5px] px-2 py-1">
        {chargerStore.chargersStatusCount?.charging} <p className="text-[11px] font-semibold text-dark-green">Charging</p>
      </div>
    </div>
  );
});
export default StatusBar;
