const SplashScreen = () => {
  return (
    <div className="relative h-screen custom-gradient flex flex-col justify-center items-center">
      <p className="audiowide-regular text-[42px] text-[#E6F6F4]">EnergAI</p>
      <p className="text-white text-[30px] font-gummy">for</p>
      <img src="/assets/svg/logo/mvm-logo.png" alt="" className="w-[160px]" />
      <div className="absolute bottom-12 rounded-[100px] w-[30%] h-[4px] bg-white"></div>
    </div>
  );
};

export default SplashScreen;
