import { useState, useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { ClipLoader } from "react-spinners";

interface Props {
  close: () => void;
}

const UpdatePasswordModal = observer(({ close }: Props) => {
  const { userStore } = useStore();
  const { user } = userStore;

  const [value, setValue] = useState({
    oldPassword: "",
    newPassword: "",
    repeatedPassword: "",
  });
  const [visibleOldPassword, setVisibleOldPassword] = useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState(false);
  const [visibleRepeatedPassword, setVisibleRepeatedPassword] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const isFormValid =
    value.oldPassword.trim() !== "" &&
    value.newPassword.trim() !== "" &&
    value.repeatedPassword.trim() !== "" &&
    value.newPassword === value.repeatedPassword;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      close();
    }, 150);
  };

  const handleConfirmEditName = async () => {
    setIsLoading(true);
    if (value.oldPassword && value.newPassword && value.repeatedPassword) {
      try {
        await userStore.updateUserPassword(
          value.oldPassword,
          value.newPassword,
          value.repeatedPassword
        );

        setIsLoading(false);
        setIsClosing(true);
        close();
      } catch (error: any) {
        if (error.isAxiosError) {
          if (error.response) {
            if (
              error.response.status === 400 &&
              error.response.data === "Incorrect email or password"
            ) {
              setShowValidation(true);
            }
          }
        }
        setIsLoading(false);
      }
    }
  };

  const validation = showValidation ? "Incorrect old password" : "";

  return (
    <>
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-9999"
      ></div>

      <div
        className="flex flex-col gap-6 rounded-t-[10px] fixed pt-12 pb-8 px-5 z-1000 bg-white bottom-0 left-0 right-0 w-full"
        style={{
          transition: isClosing
            ? "transform 0.3s ease-in"
            : translateY === 0
              ? "transform 0.3s ease"
              : "none",
          transform: isClosing
            ? "translateY(100%)"
            : `translateY(${translateY}px)`,
          overscrollBehavior: "none",
        }}
      >
        <img
          onClick={handleClose}
          className="absolute top-4 right-6"
          width={16}
          height={16}
          src="/assets/svg/exit/cross.svg"
          alt=""
        />
        <div className="font-bold text-dark-green">Change password</div>
        <div className="flex flex-col gap-2 relative w-full">
          <input
            placeholder="Old Password"
            className="text-[14px] font-medium w-full text-light-gray border-b-2 border-[#F7F7F7] py-1 focus:outline-none"
            type={visibleOldPassword ? "text" : "password"}
            name="oldPassword"
            value={value.oldPassword}
            onChange={handleInputChange}
          />
          <img
            src={
              visibleOldPassword
                ? "/assets/svg/eye/eye-open.svg"
                : "/assets/svg/eye/eye-closed.svg"
            }
            alt=""
            className="absolute cursor-pointer top-1 right-[10px]"
            onClick={() => setVisibleOldPassword(!visibleOldPassword)}
          />
        </div>
        <div className="flex flex-col gap-2 relative w-full">
          <input
            placeholder="New Password"
            className="text-[14px] font-medium w-full text-light-gray border-b-2 border-[#F7F7F7] py-1 focus:outline-none"
            type={visibleNewPassword ? "text" : "password"}
            name="newPassword"
            value={value.newPassword}
            onChange={handleInputChange}
          />
          <img
            src={
              visibleNewPassword
                ? "/assets/svg/eye/eye-open.svg"
                : "/assets/svg/eye/eye-closed.svg"
            }
            alt=""
            className="absolute cursor-pointer top-1 right-[10px]"
            onClick={() => setVisibleNewPassword(!visibleNewPassword)}
          />
        </div>
        <div className="flex flex-col gap-2 relative w-full">
          <input
            placeholder="Confirm Password"
            className="text-[14px] font-medium w-full text-light-gray border-b-2 border-[#F7F7F7] py-1 focus:outline-none"
            type={visibleRepeatedPassword ? "text" : "password"}
            name="repeatedPassword"
            value={value.repeatedPassword}
            onChange={handleInputChange}
          />
          <img
            src={
              visibleRepeatedPassword
                ? "/assets/svg/eye/eye-open.svg"
                : "/assets/svg/eye/eye-closed.svg"
            }
            alt=""
            className="absolute cursor-pointer top-1 right-[10px]"
            onClick={() => setVisibleRepeatedPassword(!visibleRepeatedPassword)}
          />
        </div>

        <p className="text-rose-600 text-[12px]">{validation}</p>

        <button
          className={`flex h-12 items-center justify-center text-[14px] w-full font-bold px-5 rounded-[5px] transition duration-200 ${isFormValid
            ? "bg-light-green text-white"
            : "bg-[#D6D6D6] text-[#515151]"
            }`}
          disabled={!isFormValid}
          onClick={handleConfirmEditName}
        >
          {isLoading ? <ClipLoader size={24} color="white" /> : "Update"}
        </button>
      </div>
    </>
  );
});

export default UpdatePasswordModal;
